import PropTypes from 'prop-types';
export var cardProps = {
  offerType: PropTypes.string.isRequired,
  bonusRibbons: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    title: PropTypes.string,
    name: PropTypes.string
  })),
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  title: PropTypes.string,
  offerImg: PropTypes.string,
  merchantName: PropTypes.string,
  merchantLogo: PropTypes.string,
  callout: PropTypes.bool,
  importantInfo: PropTypes.string,
  pointsEarn: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dollarSpend: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  redemptionType: PropTypes.string,
  offerEndDate: PropTypes.string,
  offerStartDate: PropTypes.string,
  loading: PropTypes.bool,
  prevPoints: PropTypes.number,
  newStore: PropTypes.bool,
  wideView: PropTypes.bool,
  ctaCopy: PropTypes.string,
  ctaStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.shape]),
  hasPebble: PropTypes.bool,
  activate: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  shopNow: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  findStore: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  disabledMallOffer: PropTypes.bool,
  specialTerms: PropTypes.oneOfType([PropTypes.string, PropTypes.shape]),
  lazyLoad: PropTypes.bool,
  hasFavourite: PropTypes.bool,
  merchantId: PropTypes.string,
  hasName: PropTypes.string,
  isNewCardLinkFlow: PropTypes.bool,
  isVisaEnabled: PropTypes.bool,
  isVisaAmexEnabled: PropTypes.bool
};
export var cardPropsDefaults = {
  onClick: function onClick() {
    // intentionally empty sonar
  },
  bonusRibbons: [],
  title: '',
  merchantName: '',
  merchantLogo: '',
  offerImg: '',
  callout: false,
  importantInfo: '',
  pointsEarn: '',
  dollarSpend: '',
  redemptionType: '',
  offerEndDate: '',
  offerStartDate: '',
  loading: false,
  prevPoints: 0,
  newStore: false,
  wideView: false,
  ctaCopy: '',
  ctaStatus: '',
  hasPebble: false,
  activate: '',
  shopNow: '',
  findStore: '',
  disabledMallOffer: false,
  specialTerms: '',
  lazyLoad: true,
  hasFavourite: false,
  merchantId: ''
};