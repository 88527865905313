var __jsx = React.createElement;
/** @format */

import React from 'react';
import { pointsTermCalculator, actualPointsCalculator } from "../offerUtils";
import { CardDetail, PointsEarn, PointsTerm, RedemptionDetails, PrevPoints, EarnContainer } from "./style";
var CardDetails = function CardDetails(props) {
  var formatCurrency = function formatCurrency(number) {
    return Number(number).toLocaleString();
  };
  var offerType = props.offerType,
    dollarSpend = props.dollarSpend,
    redemptionType = props.redemptionType,
    redemptionText = props.redemptionText,
    redemptionImg = props.redemptionImg,
    discountType = props.discountType;
  var pointTerms = pointsTermCalculator(offerType, dollarSpend, discountType);
  var _actualPointsCalculat = actualPointsCalculator(props),
    showPrevPoints = _actualPointsCalculat.showPrevPoints,
    actualPoints = _actualPointsCalculat.actualPoints;
  return __jsx(CardDetail, {
    offerType: offerType
  }, __jsx("div", null, __jsx("div", null, "You can earn"), __jsx(EarnContainer, null, __jsx(PointsEarn, null, showPrevPoints ? __jsx(PrevPoints, null, "was ", showPrevPoints) : null, __jsx("span", null, formatCurrency(actualPoints)), __jsx("span", null, actualPoints > 1 ? 'PTS' : 'PT')), __jsx(PointsTerm, null, pointTerms))), __jsx(RedemptionDetails, {
    redemptionType: redemptionType
  }, __jsx("div", null, redemptionText), __jsx("img", {
    src: redemptionImg,
    alt: redemptionText
  })));
};
CardDetails.defaultProps = {
  offerType: '',
  dollarSpend: '',
  redemptionText: '',
  redemptionImg: '',
  redemptionType: '',
  discountType: ''
};
export default CardDetails;