import styled from 'styled-components';
import OfferCard from "../Offer/Card";
import { CardContainer } from "../Offer/Card/style";
import { customFonts, layoutSizes, colours, fontSizes, pageTitlePaddingTopForMobile, zIndexes, pixel, mobileHeaderHeight } from "../../constants/css";
import { Container, Spinner } from "../_global";
import { BonusBar } from "../Home/style";
export var NoOffers = styled.div.withConfig({
  displayName: "style__NoOffers",
  componentId: "sc-14a8kv-0"
})(["text-align:center;h3{display:inline-block;width:150px;color:", ";font-family:", ";font-weight:normal;}"], colours.qantasGrey, customFonts.ciutadellaMed);
export var OffersWrapper = styled.div.withConfig({
  displayName: "style__OffersWrapper",
  componentId: "sc-14a8kv-1"
})(["display:flex;flex-wrap:wrap;justify-content:center;padding-bottom:1rem;min-height:470px;> div{margin:1rem auto !important;@media only screen and (min-width:", "){margin:1rem 1rem 1rem 0 !important;}}> ", "{margin:auto !important;}"], layoutSizes.desktopWidth, NoOffers);
export var FilterWrapper = styled.div.withConfig({
  displayName: "style__FilterWrapper",
  componentId: "sc-14a8kv-2"
})(["padding:1rem 0;display:block;background:", ";z-index:1;&.js-is-sticky{box-shadow:-3px 0px 3px 0 ", ";background-color:", ";z-index:10;h1{visibility:hidden;margin:0;height:0;}}@media only screen and (min-width:", "){position:sticky;top:0;z-index:15;}@media only screen and (min-width:", "){display:flex;justify-content:space-between;&.js-is-sticky{padding-top:0;h1{margin:1.5rem 0;visibility:visible;}}}"], colours.qantasWhite, colours.qantasWhite, colours.qantasWhite, layoutSizes.tabletAndDesktopWidth, layoutSizes.desktopWidth);
export var LinkMoreCards = styled.a.withConfig({
  displayName: "style__LinkMoreCards",
  componentId: "sc-14a8kv-3"
})(["font-family:", ";font-size:18px;font-weight:normal;margin:6px 0 0 13px;text-decoration:none;display:", ";@media only screen and (max-width:", "){position:absolute;right:0;}@media only screen and (min-width:", "){position:relative;right:auto;}"], customFonts.ciutadellaReg, function (props) {
  return props.webView ? 'none' : 'inline';
}, layoutSizes.mobileWidth, layoutSizes.desktopWidth);
export var MainTitle = styled.h1.withConfig({
  displayName: "style__MainTitle",
  componentId: "sc-14a8kv-4"
})(["font-family:", ";font-size:1.625rem;color:#282b26;font-weight:normal;margin:1.375rem 0;display:", ";", ""], customFonts.ciutadellaMed, function (props) {
  return props.webView ? 'none' : 'block';
}, function (props) {
  return props.paddingTop && pageTitlePaddingTopForMobile;
});
export var McoCard = styled(OfferCard).withConfig({
  displayName: "style__McoCard",
  componentId: "sc-14a8kv-5"
})(["margin:1rem 0 !important;@media only screen and (min-width:", "){margin:1rem 1rem 1rem !important;}"], layoutSizes.desktopWidth);
export var ExpiredWrapper = styled.div.withConfig({
  displayName: "style__ExpiredWrapper",
  componentId: "sc-14a8kv-6"
})(["background-color:", ";padding-bottom:2rem;"], colours.qantasIceWhite);
export var ExpiredTitle = styled.h2.withConfig({
  displayName: "style__ExpiredTitle",
  componentId: "sc-14a8kv-7"
})(["font-family:", ";font-size:1.625rem;color:#282b26;font-weight:normal;margin-bottom:0;padding-top:2rem;"], customFonts.ciutadellaMed);
export var ExpiredCopy = styled.p.withConfig({
  displayName: "style__ExpiredCopy",
  componentId: "sc-14a8kv-8"
})(["margin-top:0;color:", ";"], colours.qantasCharcoal);
export var FilterSection = styled.div.withConfig({
  displayName: "style__FilterSection",
  componentId: "sc-14a8kv-9"
})(["> div{display:flex;justify-content:flex-end;> div{box-shadow:none;}}", ""], function (_ref) {
  var isSticky = _ref.isSticky;
  return isSticky && "\n    height: 45px; // reserve the space\n    > div {\n      z-index: ".concat(zIndexes.filterSection, ";\n      background-color: ").concat(colours.qantasWhite, ";\n      position: fixed;\n      top: ").concat(pixel(mobileHeaderHeight + 0.5), ";\n      left: 0;\n      width: 100%\n    }\n  ");
});
export var LoadingContainer = styled(Container).withConfig({
  displayName: "style__LoadingContainer",
  componentId: "sc-14a8kv-10"
})(["min-height:5rem;"]);
export var Loading = styled(Spinner).withConfig({
  displayName: "style__Loading",
  componentId: "sc-14a8kv-11"
})(["position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);"]);
export var DownloadSection = styled.section.withConfig({
  displayName: "style__DownloadSection",
  componentId: "sc-14a8kv-12"
})(["background-image:linear-gradient(to right,", ",", ");"], colours.qantasWhite, colours.qantasGray91);
export var DownloadWrapper = styled.div.withConfig({
  displayName: "style__DownloadWrapper",
  componentId: "sc-14a8kv-13"
})(["text-align:center;padding-top:2rem;display:flex;flex-direction:column-reverse;> img{margin:auto;}@media only screen and (min-width:", "){flex-direction:row;text-align:left;justify-content:center;> img{margin-right:70px;max-width:249px;margin-left:0;}}"], layoutSizes.desktopWidth);
export var DownloadButton = styled.a.withConfig({
  displayName: "style__DownloadButton",
  componentId: "sc-14a8kv-14"
})(["margin-right:1rem;"]);
export var ButtonWrapper = styled.div.withConfig({
  displayName: "style__ButtonWrapper",
  componentId: "sc-14a8kv-15"
})(["display:flex;justify-content:space-evenly;padding-bottom:2rem;@media only screen and (min-width:", "){padding-bottom:0;}"], layoutSizes.desktopWidth);
export var DownloadCopy = styled.p.withConfig({
  displayName: "style__DownloadCopy",
  componentId: "sc-14a8kv-16"
})(["font-size:1.43rem;"]);
export var MainBlock = styled.div.withConfig({
  displayName: "style__MainBlock",
  componentId: "sc-14a8kv-17"
})(["@media only screen and (min-width:", "){display:flex;flex-direction:column;justify-content:center;}"], layoutSizes.desktopWidth);
export var PendingCard = styled(CardContainer).withConfig({
  displayName: "style__PendingCard",
  componentId: "sc-14a8kv-18"
})(["margin-bottom:2rem;"]);
export var PendingCallout = styled.div.withConfig({
  displayName: "style__PendingCallout",
  componentId: "sc-14a8kv-19"
})(["position:absolute;top:0;display:inline-block;background-color:", ";text-align:left;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;vertical-align:middle;padding:0.3rem 1rem 0.3rem 0.5rem;font-size:", ";border-top-left-radius:4px;border-bottom-right-radius:10px;text-transform:uppercase;letter-spacing:1px;max-width:50%;img{width:", ";vertical-align:sub;display:inline-block;}"], colours.qantasLightBlue, fontSizes.xs, fontSizes.small);
export var PendingBody = styled.p.withConfig({
  displayName: "style__PendingBody",
  componentId: "sc-14a8kv-20"
})(["font-family:", ";font-size:", ";font-weight:500;font-style:normal;font-stretch:normal;line-height:1;letter-spacing:normal;padding:0 1rem;"], customFonts.ciutadellaMed, fontSizes.large);
export var PendingInfo = styled.p.withConfig({
  displayName: "style__PendingInfo",
  componentId: "sc-14a8kv-21"
})(["font-size:", ";line-height:1.29;letter-spacing:normal;color:#666666;padding:1rem;"], fontSizes.small);
export var OptOutWrapper = styled.div.withConfig({
  displayName: "style__OptOutWrapper",
  componentId: "sc-14a8kv-22"
})(["margin:1rem auto;text-align:center;a{color:", ";text-decoration:underline;}"], colours.qantasRed);
export var BonusBarDark = styled(BonusBar).withConfig({
  displayName: "style__BonusBarDark",
  componentId: "sc-14a8kv-23"
})(["background-color:", ";margin-top:1rem;@media only screen and (min-width:", "){margin-top:0;}"], colours.qantasIceWhite, layoutSizes.desktopWidth);
export var Intro = styled.div.withConfig({
  displayName: "style__Intro",
  componentId: "sc-14a8kv-24"
})(["padding-bottom:2.625rem;button{width:100%;}@media only screen and (min-width:", "){button{width:initial;}}"], layoutSizes.desktopWidth);